import styled from 'styled-components'
import mixins from './mixins'

const Main = styled.main`
  ${mixins.sidePadding};
  margin: 0 auto;
  width: 100%;
`

export default Main
